import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import AddtoBasket from '../basket/AddToBasket'


import "./configurator.css"


var msg = null;
var req_offset = 0;
var req_model = null;

export default function Configurator(props){

    const [Data, setData] = useState({ready:false, modif:false, error:null, open:false});
    const [isChecked, setIsChecked] = useState({save:false, idttl:true, norm:true, gone:true, prices:true, minPrc:true, vmode:false, zak:false, SavePrc:true});

    const setup = useSelector(state => state.setup);
    //const CurID = useSelector(state => state.curid);
    const vendor_TTL = useSelector(state => state.content.man);
    const [selectedOption, setSelectedOption] = useState({});
    const [opn, setOpn] = useState([]);
    const [fr, setFr] = useState(0);


    useEffect(() => {
      LoadFilter();
  }, [] );
  
  let { base, id } = useParams();

  var CurID = id;

  const tggl = (idx) => {
    const updatedOpn = [...opn];
    updatedOpn[idx] = !updatedOpn[idx]; 
    setOpn(updatedOpn);
  };
  
const open = () => setData({ready:false, error:null, open:true}) 
const toggle = () => setData({...Data, open:!Data['open']})

const delFiltOption = (name) => { 
  const tmp = {... selectedOption};
  delete tmp[name];
  setSelectedOption(tmp);
};

const handleSelectChange = (event) => {
  const { name, title, value } = event.target;
  const sel = event.target;
  const selIndex = sel.selectedIndex;
  setSelectedOption({ ...selectedOption, [name]:{value:value, ttl:title, t:sel.options[selIndex].text}});
  //console.log('EV:', selectElement.options[selectedIndex].text);
};

function toggleChecked (ev){
     setIsChecked({...isChecked, [ev.currentTarget.name]: !isChecked[ev.currentTarget.name]})
}

function forceReload(qwn){
  setFr(qwn);
}


if(Data.error){
    return (
        <Fragment>
                <Fragment>
                <div className="dialog" onClick={toggle}>ASK QST: {Data.error.message}</div>
                </Fragment>
        </Fragment>
            )
} 



if(Data.ready){
    //console.log('FILTERS', msg.filters)
    //console.log('MODELS', msg.models)
    //console.log('SEL:', selectedOption);
  return(  
    <Fragment>
        <form name="filters" onSubmit={(ev)=>{GetFilters(ev)}} className='config'>
          <table>
              <tr>
                <td>
                    {
                      msg.filters.map((f, key) => (
                        key % 2 == 0? 
                        <Fragment>
                          <label key={f.name} htmlFor={f.name}>{f.label}
                            <select name={f.name} 
                                    title={f.label}
                                    onChange={handleSelectChange}
                                    value={selectedOption[f.name]? selectedOption[f.name].value : ''}
                                    className={selectedOption[f.name]? 'changed-sel' : ''}
                            >
                              {f.options.map((o, i)=>(
                                <option key={o.name} value={o.value}>{o.name}</option>
                              ))}
                            </select>
                          </label>
                          <br/>
                        </Fragment> : null
                      ))
                    }
                </td>
                <td>
                    {
                      msg.filters.map((f, key) => (
                        key % 2 !== 0? 
                        <Fragment>
                          <label key={f.name} htmlFor={f.name}>{f.label}
                            <select name={f.name} title={f.label} 
                            onChange={handleSelectChange}
                            value={selectedOption[f.name]? selectedOption[f.name].value : ''}
                            className={selectedOption[f.name]? 'changed-sel' : ''}
                            >
                              {f.options.map((o, i)=>(
                                <option key={o.name} value={o.value}>
                                {o.name}
                              </option>
                              ))}
                            </select>
                          </label>
                          <br/>
                        </Fragment> : null
                      ))
                    }
                </td>
              </tr>
          </table>

          <div className='tags'>
            {ShowFilter()}
          </div>
          <div className='fl-box'>
              <button type="submit" className="btn submit w">ОТПРАВИТЬ ЗАПРОС</button>
          </div>
    <br/><br/><br/>
          <table>
            <thead>
              {
                !Data.modif? 
                  <tr>
                    <th>{GenFilter('model')}</th>
                    <th>{GenFilter('modelGroup')}</th>
                    <th>{GenFilter('nsh')}</th>
                    <th>{GenFilter('mlen')}</th>
                  </tr>
               :
                  <tr>
                  <th>МОДЕЛЬ</th>
                  <th>КРАТКОЕ ОПИСАНИЕ</th>
                  <th>ЦЕНА</th>
                  <td></td>
                  </tr>
              }
            </thead>
            <tbody>
            {
              msg.models.map((model, key) => (
                !Data.modif?  
                  <tr>
                    <td>{model.name}{ModelImage(model.images)}</td>
                    <td>{model.modelGroup}</td>
                    <td>{model.nshGroups}</td>
                    <td className='prc' onClick={(ev) => GetModifications(ev, model.name, selectedOption['type'].value, '0')}>{model.price} руб.</td>
                  </tr>
                  :
                  <Fragment>
                    <tr>
                      <td>{model.name}
                        <img src={'https://owen.ru/upl_files/modules/sensors_selector/images/pages'+model.image.src} alt={model.image.name} title={model.image.name}/>
                      </td>
                      <td>{model.description}</td>
                      <td className='prc'>{model.price+'\u00A0₽'}</td>
                      <td><AddtoBasket itm={
                        {id:'t'+model.id, ttl:props.ttl, mdl:model.name, prc:model.price}
                      } ttl={{t:model.name, p:props.p, v: props.man}} fr={forceReload}/></td>
                    </tr>
                    {
                      key>=19? 
                        <tr><td colSpan="4" className="tac">
                          {
                            req_offset >= 20? <span className="btn submit w45 mh" onClick={(ev)=>GetModifications(ev, req_model, selectedOption['type'].value, '-20')}>НАЗАД</span> :null
                          }
                          <span className="btn submit w45" onClick={(ev)=>GetModifications(ev, req_model, selectedOption['type'].value, '+20')}>ЕЩЕ..</span>

                        </td></tr>
                      :null
                    }
                  </Fragment>
              ))
            }
            </tbody>
          </table>
        </form>
    </Fragment>
    )
} 

return(
  <Fragment>
      <form name="konfig" onSubmit={(ev)=>{GetModelsJ(ev)}} className="config">
          <button type="submit" className="btn submit w">ЗАГРУЗИТЬ ФИЛЬТРЫ</button>
      </form>
  </Fragment>
 )   

 function ShowFilter(){
  let paramName;
  let i=0;
  let m =[];
    for (paramName in selectedOption) { 
      m[i]={name:paramName, 
        value:selectedOption[paramName].value, 
        ttl:selectedOption[paramName].ttl,
        t:selectedOption[paramName].t
       };
      i++;
    }
    return(
      m.map((prm, key)=>(
        <Fragment>
          <span key={key}>
            {prm.ttl+':'+prm.t}<div className='delflt' onClick={(ev)=>{delFiltOption(prm.name)}}>x</div>
          </span>
        </Fragment>
      ))
    )
 }

function GenFilter(filterName){
  return(
      msg.filters.map((f, key) => (
      f.name == filterName ?
        <Fragment>
        <label htmlFor={f.name}>{f.label}</label>
            <select key={f.name} 
                    name={f.name} 
                    title={f.label}
                    onChange={handleSelectChange}
                    className={selectedOption[f.name]? 'changed-sel' : ''} 
                    value={selectedOption[f.name]? selectedOption[f.name].value : ''}
            >
              {f.options.map((o, i)=>(
                <option key={o.name} value={o.value} disabled={o.disabled || false}>
                {o.name}
              </option>
              ))}
            </select>
        </Fragment> : null
      ))
  )
}

function ModelImage(imgs) {
  var img = {src:[], name:[]};
  imgs.map((i, key) => (img.src[key]=i.src, img.name[key]=i.name));
  return(
    <Fragment>
      <img src={'https://owen.ru/upl_files/modules/sensors_selector/images/pages'+img.src[0]} name={img.name[0]} width='100'/>
    </Fragment>
  )  
}

function GetModelsTST(ev){
  ev.preventDefault()
  let paramName;
  let uri ='';
  const form = new FormData()

  for (paramName in selectedOption) {
    form.append([paramName], selectedOption[paramName].value)
    uri = uri + paramName + '=' + encodeURIComponent(selectedOption[paramName].value) + '&'
     console.log("URI:", uri.slice(0, -1));
  }

}

function GetModifications(ev, model, type, offset){
        ev.preventDefault()
        let paramName;
        let uri ='';

        const form = new FormData()

        form.append('type', type);
        form.append('model', model);
        form.append('_limit', 20);
        form.append('_sotr_by', '+price');

        uri +='type='+encodeURIComponent(type);
        uri +='&model='+encodeURIComponent(model);
        uri +='&_limit='+'20';
        uri +='&_sotr_by='+encodeURIComponent('+price');

        if(offset!=='0'){
            if(offset==='+20'){
              req_offset += 20;
            } else {
              req_offset -= 20;
            }
            uri +='&_offset='+encodeURIComponent(req_offset);
        } else {
          req_model = model;
        }

        const MyInit = {
          method: 'POST',
          redirect: 'follow',
          body: form
        };
    
        fetch('https://owen.ru/upl_files/modules/sensors_selector/server/get_modifications.php?'+uri, MyInit)
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(response.text() + 'Something went wrong ...');
            }
          })
          .then(data => {
            //let m = msg;
            msg.models = data;
            setData({...Data, modif:true});
          })
          .catch(error => {
            setData({...Data, error:error});
          }
          );
    }

function GetFilters(ev){
      ev.preventDefault()
      let paramName;
      let uri ='';

      const form = new FormData()
      for (paramName in selectedOption) {
        form.append([paramName], selectedOption[paramName].value)
        uri = uri + paramName + '=' + encodeURIComponent(selectedOption[paramName].value) + '&'
      }
      uri = uri.slice(0, -1);

      const MyInit = {
        method: 'POST',
        redirect: 'follow',
        body: form
      };
  
      fetch('https://owen.ru/upl_files/modules/sensors_selector/server/get_models.php?'+uri, MyInit)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.text() + 'Something went wrong ...');
          }
        })
        .then(data => {
          msg = data;
          setData({...Data, ready:true, modif:false});
        })
        .catch(error => {
          setData({...Data, error:error});
  
        }
        );
  }


    function GetModelsJ(ev){
      ev.preventDefault()
      let paramName;
      let uri ='';

      const form = new FormData()
      for (paramName in selectedOption) {
        form.append([paramName], selectedOption[paramName].value)
        uri = uri + paramName + '=' + encodeURIComponent(selectedOption[paramName].value) + '&'
      }
      uri = uri.slice(0, -1);

      const MyInit = {
        method: 'POST',
        redirect: 'follow',
        body: form
      };
  
      fetch(setup.domen+setup.folder+'getowenjson.php', MyInit)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.text() + 'Something went wrong ...');
          }
        })
        .then(data => {
          msg = data;
          setData({...Data, ready:true});
        })
        .catch(error => {
          setData({...Data, error:error});
  
        }
        );
  }

 function SaveFilter(ev){
  ev.preventDefault()
  const jsonString = JSON.stringify(selectedOption);
  console.log('JSON:', jsonString);

  const form = new FormData()
  form.append('json', jsonString);
  form.append('fname', CurID);
  form.append('type', 'save');
  
      const MyInit = { 
        method: 'POST',
        body: form
        };

    fetch(setup.domen+setup.folder+'configurator.php', MyInit)
    .then(response => {
    if (response.ok) {
    return response.json();
    } else {
    throw new Error(response.text()+'Something went wrong ...');
    }
    })
    .then(data => {
    setData({...Data, ready:true});
    })
    .catch(error => {
    setData({...Data, error:error});
    });

}

function LoadFilter(){
  //ev.preventDefault()
  //const jsonString = JSON.stringify(selectedOption);
  //console.log('JSON:', jsonString);

  const form = new FormData()
  form.append('fname', CurID);
  form.append('type', 'load');
  
      const MyInit = { 
        method: 'POST',
        body: form
        };

    fetch(setup.domen+setup.folder+'configurator.php', MyInit)
    .then(response => {
    if (response.ok) {
    return response.json();
    } else {
    throw new Error(response.json()+'Something went wrong ...');
    }
    })
    .then(data => {
      if(!data.resp){ setSelectedOption(data) }
    //setData({...Data, ready:true});
    })
    .catch(error => {
    setData({...Data, error:error});
    });

}



function SetDATA(ev, name, idx){
  tggl(idx);
  ev.preventDefault();
  let frmName = 'fltedit'+idx;
  var Form = document.forms[frmName];
  console.log('EDIT VALUE:', name);
  console.log('EDIT IDX:', idx);
  setSelectedOption({ ...selectedOption, [name]:{...selectedOption[name], value:Form.elements[name].value, t:Form.elements[name].value}});
  
}

} //************ */