import React, { useState, useEffect, Fragment } from 'react';
import useMedia from "./js/useMedia"
import {useWindowScroll} from 'react-use'
import BasketQwn from "./basket/BasketQwn"
import SearchForm from "./SEARCH/SearchForm"
//import Loader from "./Loader"

import {
    Link,
    NavLink
} from "react-router-dom";
var ShowPhone = false;

export default function Shapka(){

    let [isMenu, setMenu] = useState(false)
    let [logoClass, setLogoClass] = useState('logo z10');
    let [MenuClass, setMenuClass] = useState('App-header trr');
    //let [ShowPhone, setPhneFlag] = useState(false);

    let small = useMedia("(max-width:420px)");
    let large = useMedia("(min-width:650px)");
    const {x, y} = useWindowScroll();

    useEffect(() => { 
        if( y>5 ) {setLogoClass('logo z6')} else {setLogoClass('logo z10')}
        if( y>70 ) {
            setMenuClass('App-header trl')
            ShowPhone = true;
        } else {
            setMenuClass('App-header trr')
            ShowPhone = false;
        }
    }, [y] );

//console.log('Y', y);
const toggleMenu =() => isMenu ? setMenu(false) : setMenu(true);

const punkts = (cls) => {
  return <ul className={cls} onClick={small? toggleMenu : null}>
          <li><NavLink exact to="/">КАТАЛОГ</NavLink></li>
          <li><NavLink to="/about">О НАС</NavLink></li>
          <li><NavLink to="/news">НОВОСТИ</NavLink></li>
          <li><NavLink to="/shop">КОРЗИНА <BasketQwn/></NavLink></li>
          {
          //<li><NavLink to="/info/uslugi">УСЛУГИ</NavLink></li>
          }
          <li><NavLink to="/partners">ПАРТНЕРЫ</NavLink></li>
          <li><NavLink to="/contacts" className="red b">КОНТАКТЫ</NavLink></li>
          {
          ShowPhone?
                        <Fragment>
                                <li className='TelOnMenu'>
                                    <a href="tel:+73517311405">(351) 731-14-05</a>
                                </li>
                                <li className='TelOnMenu'>
                                    <a href="mailto:sales@indelta.ru">sales@indelta.ru</a>
                                </li>
                        </Fragment> : null
          }
        </ul>
      }    
      
return(
    <Fragment>
        {
            large ? <Fragment>
            <div className="NavPenel sticky">{punkts(MenuClass)}
            <SearchForm/>
            </div> 
            <Link to="/"><img src="/svg/inlogo.svg" className={logoClass} title="НА ГЛАВНУЮ - Дельта инжиниринг" alt="НА ГЛАВНУЮ - Дельта инжиниринг"/></Link>
            </Fragment>
            : 
            <div key="nav" className="NavPenel sticky">
                <Link to="/"><img src="/svg/inlogo.svg" className={logoClass} title="НА ГЛАВНУЮ - Дельта инжиниринг" alt="НА ГЛАВНУЮ - Дельта инжиниринг"/></Link>
                <img src="/svg/menu.svg" className="m-top-menu" onClick={toggleMenu} alt="меню"/>
                        {
                        isMenu ? 
                            punkts('Mob-header')
                        :null
                        }
            </div>
        }
        {small? isMenu ? <div key="overlay" className="overlay" onClick={toggleMenu}/>:null:null}
    </Fragment>
)

}