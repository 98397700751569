import React, { useState, useEffect, Fragment } from 'react'
import Loader from "../Loader"
import SearchForm from "./SearchForm"
import useMedia from "../js/useMedia"
import {useCss} from "react-use"
import { useTimeoutFn } from 'react-use'
import {
    useLocation
  } from 'react-router-dom'

//var yatext = null;

export default function LoadSearchResult(){

    const [isReady, cancel, reset] = useTimeoutFn(ya_init, 1500);
    const [isLoaded, Lcancel, Lreset] = useTimeoutFn(ClearHTML, 4000);
    const [ShowResult, setShowResult] = useState('none');
    let large = useMedia("(min-width:650px)");

    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();
    let mode = query.get('mode');

    useEffect(() => {
        if(mode === null) { 
            ya(window,document,'yandex_site_callbacks');
        }
        reset();
      }, [] );

      const yasrch = useCss({
        display:ShowResult,
        justifyContent: 'space-evenly',
        backgroundColor: 'transparent  !important',
      });

const hops = () => {console.log('HOBANA!');}

return(<Fragment>
    {
        large? null: <SearchForm/>
    }
    {
        ShowResult==='none'? large? <Loader/> :null :null
    }
    {
         <div id="ya-site-results" className={'nobg ' + yasrch} data-bem="{&quot;tld&quot;: &quot;ru&quot;,&quot;language&quot;: &quot;ru&quot;,&quot;encoding&quot;: &quot;&quot;,&quot;htmlcss&quot;: &quot;1.x&quot;,&quot;updatehash&quot;: true}">
         </div>
    }        
</Fragment>
)

function ya_init() {
    if(mode === null) {
        window.Ya.Site.Results.init();
    }
    cancel();
}

function ClearHTML() {
    var myDiv = document.getElementById('ya-site-results');
    ProcessData(myDiv.innerHTML); 
    Lcancel();
}

function ya(w,d,c){
  if(mode === null) {
    var s=d.createElement('script'), h=d.getElementsByTagName('script')[0];
    s.type='text/javascript';
    s.async=true;
    s.charset='utf-8';
    s.src=(d.location.protocol==='https:'?'https:':'http:')+'//site.yandex.net/v2.0/js/all.js';
    h.parentNode.insertBefore(s,h);
    //(w[c]||(w[c]=[])).push(function(){Ya.Site.Results.init();})
  }
  return null;
}

function ShowContent(txt){
    return( 
        <div id="ya-site-results" dangerouslySetInnerHTML={{ __html: sanitize(txt)}}/>
    ) 
}

    function sanitize(html) {
        var doc = document.createElement('div');
        doc.className = 'content';
        doc.innerHTML = html;
        return doc.innerHTML;
      }

function ProcessData(html){
    const form = new FormData()
    form.append('html', html);
    form.append('domen', 'ya_srch');
    form.append('clrhtml', 'on');
    form.append('trnshtml', 'on');

const MyInit = { 
          method: 'POST',
          body: form
          };
    //fetch(setup.domen+setup.folder+'site-get-itm.php', MyInit)
    fetch('/api/clearhtml', MyInit)
    .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(response.text()+'Something went wrong ...');
        }
    })

    .then(data => {   
        //yatext = 'РАБОТАЕТ!';
        var doc = document.getElementById('ya-site-results');
        doc.innerHTML = sanitize(data); 
        setShowResult('flex');
    })
    .catch(error => {
    });
}    
} 